import React from "react"
import vslogo from "../vs.png"
import logo from "../logo.png"
import designIcon from "./Assets/design2.png"
import devicesIcon from "./Assets/devices (1).png"
import ukIcon from "./Assets/uk.png"
import supportIcon from "./Assets/customerSupport.png"
import marketingIcon from "./Assets/marketing2.png"
import photographyIcon from "./Assets/photography2.png"

// import project1 from "./Assets/ShiYe_Apartment.png";
import project2 from "./Assets/beach02.jpg"
import Comfort_Home from "./Assets/Comfort_Home.jpg"
import Comfort_Home02 from "./Assets/Comfort_Home02.jpg"
import Comfort_Home03 from "./Assets/Comfort_Home03.jpg"
import Comfort_Home04 from "./Assets/Comfort_Home04.jpg"
import Comfort_Home05 from "./Assets/Comfort_Home05.jpg"
import Comfort_Home06 from "./Assets/Comfort_Home06.jpg"
import Comfort_Home07 from "./Assets/Comfort_Home07.jpg"

// import smallEarth from "./Assets/earth.png"

export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: vslogo,
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        className: "header0-item",
        children: {
          href: "/",
          children: [{ children: "Home", name: "text" }],
        },
      },
      {
        name: "item1",
        className: "header0-item",
        children: {
          href: "/portfolio",
          children: [{ children: "Portfolio", name: "text" }],
        },
      },
      {
        name: "item2",
        className: "header0-item",
        children: {
          href: "/contact",
          children: [{ children: "Contact Us", name: "text" }],
        },
      },
      {
        name: "item3",
        className: "header0-item",
        children: {
          href: "/about",
          children: [{ children: "About", name: "text" }],
        },
      },
      // {
      //   name: "item4",
      //   className: "header0-item",
      //   children: {
      //     href: "/video",
      //     children: [{ children: "Sample", name: "text" }],
      //   },
      // },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
}
export const Banner01DataSource = {
  wrapper: { className: "banner0" },
  textWrapper: { className: "banner0-text-wrapper" },
  title: {
    className: "banner0-title",
    children: logo,
  },
  content: {
    className: "banner0-content",
    children: "Best virtual tours with Virtual Start",
  },
  button: { className: "banner0-button", children: "Learn More" },
}
//Feature 1
export const Feature10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: "https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: {
    className: "content1-title",
    children: "Share 3D Virtual Tours Anywhere",
  },
  content: {
    className: "content1-content",
    children:
      "All 3D virtual tours created by Virtual Start can be embedded into any webpage on your website",
  },
}

//Feature 2
export const Feature20DataSource = {
  wrapper: { className: "home-page-wrapper content2-wrapper" },
  OverPack: { className: "home-page content2", playScale: 0.3 },
  imgWrapper: { className: "content2-img", md: 10, xs: 24 },
  // img: {
  //   children: smallEarth,
  // },
  textWrapper: { className: "content2-text", md: 14, xs: 24 },
  title: {
    className: "content2-title",
    children: "360° Drone Photography & Virtual Tours ",
  },
  content: {
    className: "content2-content",
    children:
      "Virtual Start combine the highest quality 360° photography with world-class VR technology to make the best 3d virtual tours.",
  },
}

//Feature 3
// export const Feature30DataSource = {
//   wrapper: { className: "home-page-wrapper content2-wrapper" },
//   OverPack: { className: "home-page content2", playScale: 0.3 },
//   imgWrapper: { className: "content2-img", md: 10, xs: 24 },
//   img: {
//     children: "https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png",
//   },
//   textWrapper: { className: "content2-text", md: 14, xs: 24 },
//   title: { className: "content2-title", children: "分布式中间件" },
//   content: {
//     className: "content2-content",
//     children:
//       "金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。",
//   },
// }

//New feature4
export const Feature40DataSource = {
  wrapper: { className: "home-page-wrapper content6-wrapper" },
  OverPack: { className: "home-page content6" },
  textWrapper: { className: "content6-text", xs: 24, md: 10 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: "Why 3D Virtual Tours?",
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children:
          "Give your clients and visitors a realistic and immersive experience instead of basic 2D images",
      },
    ],
  },
  img: {
    children: "https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png",
    className: "content6-img",
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: "block0",
        img: {
          children:
            "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          className: "content6-icon",
        },
        title: {
          className: "content6-title",
          children: "3D virtual tours boost sales",
        },
        content: {
          className: "content6-content",
          children:
            "Make your property or company stand out with a comprehensive virtual tour created by Virtual Start. Clients love 360 degree tours much more than basic pictures or monotonous videos.",
        },
      },
      {
        name: "block1",
        img: {
          className: "content6-icon",
          children:
            "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
        },
        title: {
          className: "content6-title",
          children: "3D virtual tours enhance brand image",
        },
        content: {
          className: "content6-content",
          children:
            "Although 360° tours are becoming increasingly popular, a lot of companies still aren’t using them, overestimating their cost or difficulty.",
        },
      },
      {
        name: "block2",
        img: {
          className: "content6-icon",
          children:
            "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
        },
        title: {
          className: "content6-title",
          children: "3D virtual tours engage with a realistic experience",
        },
        content: {
          className: "content6-content",
          children:
            "Virtual property tours are like a open day. Potential leads can access the tour anytime they want 24 hours a day from any device. Those who view the tour are transported into the scence and can fully immerse them selves in the details",
        },
      },
    ],
  },
}

//Product and Services
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "Product and Services" }],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://user-images.githubusercontent.com/56039716/101296664-8a921000-381c-11eb-882c-85c09a8924cf.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "SPECTACULAR BESPOKE VIRTUAL TOURS",
            },
            {
              name: "content",
              children:
                "•	Virtual Start carefully match branding guidelines with your marketing needs and custom design to your exact requirement. All of virtual tours with enhanced features such as added video, custom navigation and more.",
            },
            {
              name: "content",
              children:
                "•	If you are an estate agent, or you are in a business that would require a number of virtual tours, then we can discuss a pricing plan and potentially agree on a flat fee for all the work required.",
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Google_Street_View_icon.svg/1200px-Google_Street_View_icon.svg.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "GOOGLE STREET VIEW VIRTUAL TOURS",
            },
            {
              name: "content",
              children:
                "•	We are expert on creating Google Street view virtual tours and can also help with adding 360° images to your business profile on Google maps.",
            },
            {
              name: "content",
              children:
                "•	We help out clients build initial trust with their audience, break down common trust barriers and in turn increase the footfall of prequalified customers doing business with them.",
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://user-images.githubusercontent.com/56039716/101296670-9251b480-381c-11eb-8c29-1ccc9a25a6ac.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "360° VIDEO & MANY MORE",
            },
            {
              name: "content",
              children:
                "•	With very high levels of user engagement, a short 360° video is the optimum marketing tool to attract social media users on Facebook and YouTube.",
            },
            {
              name: "content",
              children:
                "•	Stand out in search results and attract more customers, drive traffic to your website and get an advantage over local competitors.",
            },
            {
              name: "content",
              children: "•	We can deliver you a quote within 24 hours.",
            },
          ],
        },
      },
    ],
  },
}

//Recent Projects
export const Content50DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "Recent Projects", className: "title-h1" },
      {
        name: "content",
        className: "title-content",
        children:
          "We have strong expertises in hotel and service apartment industry",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/Sunset-Lodge/",
          img: {
            children: Comfort_Home,
            className: "kiz1kwq2swo-editor_css",
          },
          content: { children: "Sunset Lodge" },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/LakeFront/",
          img: {
            children: project2,
            className: "kiz1kgozk5u-editor_css",
          },
          content: { children: "LakeFront Apartments" },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/RiverApartment/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home02,
            className: "kiz15f2s426-editor_css",
          },
          content: { children: "James Hotel" },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home04,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "HaiBian Apartments" },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/RiverApartment/",
          img: {
            children: Comfort_Home05,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "WenXin Apartments" },
        },
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home06,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "Lang Apartments" },
        },
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/RiverApartment/",
          img: {
            children: Comfort_Home07,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "JingDian Apartments" },
        },
      },
    ],
  },
}

//WHY WORK WITH US ( ABOUT US )
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: "Why work with us",
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: "100% CUSTOMER SATISFICATION",
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children: designIcon,
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "Bespoke design" },
          content: {
            className: "content3-content",
            children:
              "Make a virtual tour trully yours by adding a custom made interface and lots of functionality",
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children: devicesIcon,
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: "Optimised for all devices",
          },
          content: {
            className: "content3-content",
            children:
              "Built based on the latest HTML5 technology, our virtual tours work seamlessly across all devices",
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children: ukIcon,
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "Cover all UK" },
          content: {
            className: "content3-content",
            children:
              "Our services stretch all over the UK. Strategically based in London, no place is too far",
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children: supportIcon,
          },
          textWrapper: { className: "content3-text" },
          title: { className: "content3-title", children: "Customer support" },
          content: {
            className: "content3-content",
            children:
              "Our customer support, rated by our clients as excellent, will assist any query within the next 24 hours",
          },
        },
      },
      {
        name: "block4",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children: marketingIcon,
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: "Marketing Expertise",
          },
          content: {
            className: "content3-content",
            children:
              "Our team consist of marketing specialist can help to optimise your online marketing strategy with 360° virtual tour",
          },
        },
      },
      {
        name: "block5",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children: photographyIcon,
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: "Professional photography",
          },
          content: {
            className: "content3-content",
            children:
              "Our goal is to create high quality photographs that will exceed your expectations",
          },
        },
      },
    ],
  },
}
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block0",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          className: "logo",
          children:
            "https://user-images.githubusercontent.com/56039716/102727525-03fe2800-431e-11eb-9583-99462a996083.png",
        },
        childWrapper: {
          className: "slogan",
          children: [
            {
              name: "content0",
              children: "Virtual Start to Start Virtual",
            },
          ],
        },
      },
      {
        name: "block1",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Product" },
        childWrapper: {
          children: [
            {
              name: "link0",
              href: "/",
              children: "Bespoke Virtual Tour",
            },
            { name: "link1", href: "/", children: "Google Stree View" },
            { name: "link2", href: "/", children: "360° Video" },
          ],
        },
      },
      {
        name: "block2",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "About" },
        childWrapper: {
          children: [
            { href: "/faq", name: "FAQ", children: "FAQ" },
            { href: "/contact", name: "Contact Us", children: "Contact Us" },
          ],
        },
      },
      {
        name: "block3",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "Resource" },
        childWrapper: {
          children: [
            { href: "/portfolio", name: "Blog", children: "Blog" },
            { href: "/portfolio", name: "Portfolio", children: "Portfolio" },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: <span>©2020 by Virtual Start All Rights Reserved</span>,
  },
}

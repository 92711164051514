import React from "react"
import TweenOne from "rc-tween-one"
import { Menu } from "antd"
import { Link } from "gatsby"
import { getChildrenToRender } from "./utils"

const { Item } = Menu

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneOpen: undefined,
    }
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen
    this.setState({
      phoneOpen,
    })
  }

  render() {
    const { dataSource, isMobile, ...props } = this.props
    const { phoneOpen } = this.state
    const navData = dataSource.Menu.children
    const navChildren = navData.map(item => {
      const { children: a, subItem, ...itemProps } = item
      return (
        <Item key={item.name} {...itemProps}>
          <Link
            {...a}
            href={a.href}
            to={a.href}
            className={`header0-item-block ${a.className}`.trim()}
          >
            {a.children.map(getChildrenToRender)}
          </Link>
        </Item>
      )
    })
    const moment = phoneOpen === undefined ? 300 : null
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: "from" }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
        >
          <TweenOne
            animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
            {...dataSource.logo}
          >
            <Link href="/">
              <img
                width="100%"
                src={dataSource.logo.children}
                alt="virtual start"
              />
            </Link>
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick()
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    height: 0,
                    duration: 300,
                    onComplete: e => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = "auto"
                      }
                    },
                    ease: "easeInOutQuad",
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? "inline" : "horizontal"}
              defaultSelectedKeys={["sub0"]}
              theme="dark"
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    )
  }
}

export default Header

import React, { useEffect, useState } from "react"

import { Layout } from "antd"
import { enquireScreen } from "enquire-js"
import Nav from "../Home/Nav0"
import Footer1 from "../Home/Footer1"

import { Nav00DataSource, Footer10DataSource } from "../Home/data.source.js"

const { Header } = Layout

const Container = ({ children }) => {
  let isMobile
  enquireScreen(b => {
    isMobile = b
  })
  const [Mobile, setMobile] = useState(isMobile)

  useEffect(() => {
    enquireScreen(b => {
      setMobile({ isMobile: !!b })
    })
  }, [])

  return (
    <Layout>
      <Header>
        <Nav dataSource={Nav00DataSource} isMobile={isMobile} />
      </Header>

      {children}

      <Footer1 dataSource={Footer10DataSource} isMobile={isMobile} />
    </Layout>
  )
}

export default Container
